import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { sign } from 'jsonwebtoken';
import './Mobile.css';
import Artwork1 from "../../images/mobile/Artwork1.png";
import Artwork2 from "../../images/mobile/Artwork2.jpg";
import Artwork3 from "../../images/mobile/Artwork3.png";
import Artwork3A from "../../images/mobile/ArtworkStudentReportExample.png";
import Artwork4 from "../../images/mobile/Artwork4.png";
import Artwork6 from "../../images/mobile/Artwork6.jpg";
import Artwork7 from "../../images/mobile/Artwork7.jpg";
import Artwork8 from "../../images/mobile/Artwork8.jpg";



const Mobile = () => {
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const [isPremiumPlus, setIsPremiumPlus] = useState(true);

    const [basePrice, setBasePrice] = useState<number>(0);
    const [productList, setProductList] = useState<Array<any>>([]);
    const [price3Months, setPrice3Months] = useState<number>(0);

    const YouTubeVideo1 = 'https://youtu.be/U3qV6JtctMg';
    const YouTubeVideo2 = 'https://youtu.be/OBb4Hg1VjDM';

    const artworksAndVideos = [
        Artwork1, YouTubeVideo1, Artwork2, Artwork3, YouTubeVideo2, Artwork3A, Artwork4, Artwork6, Artwork7, Artwork8
    ];

    const jwtSecret = process.env.REACT_APP_JWT_SECRET;
    if (!jwtSecret) throw new Error("JWT_SECRET is not defined in environment variables");

    const checkboxItems = {
        1: { text: "ไลฟ์สอนสดฟรี", premiumPlusOnly: false },
        2: { text: "ไลฟ์สอนสดพรีเมียม", premiumPlusOnly: false },
        3: { text: "ชมคลิปบทเรียนสั้น", premiumPlusOnly: false },
        4: { text: "ชมคลิปติวสอบเข้าโรงเรียนดัง", premiumPlusOnly: false },
        5: { text: <><span>รายงานผลการเรียน</span><br /><span>พร้อมคำแนะนำ</span></>, premiumPlusOnly: true },
        6: { text: "หนังสือประกอบการเรียน", premiumPlusOnly: true },
    };

    const generateToken = (phoneNumber: string, subject: string) => {
        return sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool', algorithm: 'HS256', noTimestamp: true, jwtid: phoneNumber, subject: subject
        });
    };
    const userToken = generateToken("9876543210", "1231234561");
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };



    const renderCheckbox = (id: string | number, text: React.ReactNode, isChecked: boolean): JSX.Element => (
        <li key={id}>
            <input type="checkbox" checked={isChecked} readOnly />
            <label htmlFor={`mobile-checkbox-${id}`}>{text}</label>
        </li>
    );

    const renderEmptyCheckbox = (id: string | number, text: React.ReactNode): JSX.Element => (
        <li key={id}>
            <span className="mobile-empty-checkbox">—</span>
            <label htmlFor={`mobile-checkbox-${id}`}>{text}</label>
        </li>
    );

    const handlePremiumButton = () => history.push(accessData.accessToken ? '/pay-mobile' : '/pay-mobile-guest');

    const handlePremiumPlusButton = () => history.push(accessData.accessToken ? '/pay-mobile-premium' : '/pay-mobile-guest?premiumPlus');

    const reloadTransaction = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/v4/method/paymentSystem?access_token=${userToken}&all=true`;
            const response = await fetch(url, { method: 'GET' });
            if (!response?.ok) throw new Error(`Failed to fetch payment system. Status: ${response?.status}`);
            const data = await response.json();
            setProductList(data.product_list.sort((a: { product_id: number }, b: { product_id: number }) => b.product_id - a.product_id));
        } catch (error) {
            console.error(`Cannot load payment system: ${(error as Error).message}`);
        }
    };

    const fetchAdminData = async (
        endpoint: string, setter: (value: any) => void, defaultValue: any, processor: (value: any) => any = (value) => value
    ) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/payment/admin/${endpoint}`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(response.statusText);
            const result = await response.json();
            const value = result?.data?.[0]?.value ?? defaultValue;
            setter(processor(value));
        } catch (error) {
            console.error(`Error fetching ${endpoint}:`, error);
        }
    };

    const handlePaymentButton = () => {
        if (isPremiumPlus) {
            history.push(accessData.accessToken ? '/pay-mobile-premium' : '/pay-mobile-guest?premiumPlus');
        } else {
            history.push(accessData.accessToken ? '/pay-mobile' : '/pay-mobile-guest');
        }
    };



    useEffect(() => {
        reloadTransaction();
        fetchAdminData('plus-price3', (value) => setPrice3Months(Number(value)), 0);
    }, []);

    useEffect(() => {
        const priceData = productList.find(product => product.description.includes("3 Months"));
        if (priceData) { setBasePrice(Number(priceData.price)) }
    }, [productList]);



    return (
        <div className="mobile-container">

            {artworksAndVideos.map((item, index) => (
                item.includes('youtu.be') ? (
                    <div key={index} className="video-container">
                        <iframe
                            width="100%" height="315"
                            src={`https://www.youtube.com/embed/${item.split('youtu.be/')[1]}`}
                            title={`YouTube Video Player ${index}`} frameBorder="0" allowFullScreen
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                ) : (
                    <img key={index} src={item} alt={`Artwork ${index + 1}`} className="artwork" />
                )
            ))}

            {!isBrowser ? (
                <div className="mobile-pay-button-group">
                    <div
                        className={`mobile-pay-category-button ${!isPremiumPlus ? 'active' : ''}`} onClick={() => setIsPremiumPlus(false)}
                    >
                        Premium
                    </div>
                    <div
                        className={`mobile-pay-category-button ${isPremiumPlus ? 'active' : ''}`} onClick={() => setIsPremiumPlus(true)}
                    >
                        Premium Plus
                    </div>
                </div>
            ) : null}

            {!isBrowser ? (
                <div className="mobile-pay-starting-price">
                    เริ่มต้น แพ็กเกจ 3 เดือน<br />
                    ราคา {isPremiumPlus ? (basePrice + price3Months).toLocaleString() : basePrice.toLocaleString()} บาท
                </div>
            ) : null}

            <div className="mobile-features">
                {isBrowser ? (
                    <div className="mobile-checkbox-list">
                        <div className="mobile-price-plan-container">
                            <div className="mobile-price-plan-column">
                                <div className="mobile-plan-title">Premium</div>
                                <div className="mobile-plan-subtitle">เริ่มต้น <b>{basePrice.toLocaleString()}</b> บาท / 3 เดือน</div>
                                <ul>
                                    {Object.entries(checkboxItems).map(([id, { text }], index) => (
                                        index <= 3 ? renderCheckbox(id, text, true) : renderEmptyCheckbox(id, text)
                                    ))}
                                </ul>
                                <button className="mobile-pay-button" onClick={handlePremiumButton}>สมัครใช้บริการ</button>
                            </div>

                            <div className="mobile-price-plan-column">
                                <div className="mobile-plan-title">Premium Plus</div>
                                <div className="mobile-plan-subtitle">เริ่มต้น <b>{(basePrice + price3Months).toLocaleString()}</b> บาท / 3 เดือน</div>
                                <ul>
                                    {Object.entries(checkboxItems).map(([id, { text }]) => (
                                        renderCheckbox(id, text, true)
                                    ))}
                                </ul>
                                <button className="mobile-pay-button" onClick={handlePremiumPlusButton}>สมัครใช้บริการ</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mobile-checkbox-button-container">
                        <div className="mobile-checkbox-list">
                            <ul>
                                {Object.entries(checkboxItems).map(([id, { text, premiumPlusOnly }]) => (
                                    premiumPlusOnly ? (
                                        isPremiumPlus ? renderCheckbox(id, text, true) : renderEmptyCheckbox(id, text)
                                    ) : renderCheckbox(id, text, true)
                                ))}
                            </ul>
                        </div>
                        <button className="mobile-pay-button" onClick={handlePaymentButton}>
                            สมัครใช้บริการ
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Mobile;
