import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generateToken } from '../../utility/Token';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import { QRCodeToDataURLOptions } from 'qrcode';
import { toast, ToastContainer } from 'react-toastify';
import moment, { Moment } from 'moment';
import qrcode from 'qrcode';
import generatePayload from 'promptpay-qr';
import AddressModal from '../../components/address-modal/AddressModal';

import './PayMobilePremium.css';
import lineIcon from '../../images/other/line.svg'
import loading from '../../images/loading/loading_spinner.svg';
import promptPay from '../../images/bank/PromptPay2.png';
import uploadIcon from '../../images/icon/upload-file-white.svg';



const PayMobilePremium = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const [amount, setAmount] = useState<number>(0);
    const [cutoffDate, setCutoffDate] = useState<moment.Moment>(moment(''));
    const [dailyRate, setDailyRate] = useState<number>(0);
    const [price3Months, setPrice3Months] = useState<number>(0);
    const [price6Months, setPrice6Months] = useState<number>(0);
    const [qrCodeDataURL, setQrCodeDataURL] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedProductId, setSelectedProductId] = useState<string>('');
    const [sendSubmit, setSendSubmit] = useState(false);
    const [showMessenger, setShowMessenger] = useState(false);

    const [address, setAddress] = useState<string>('');
    const [addressAppend, setAddressAppend] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProductExpanded, setIsProductExpanded] = useState(false);

    const addressMaxLength = 200;
    const at = accessData.accessToken ?? '';
    const aid = accessData.accountId ?? '';
    const accountName = accessData.account?.fullname || '';

    const currentPremium = transaction.transactionList.find((t) => t.status === '1');
    const sortedProductList = productList.slice().sort((a, b) => b.product_id - a.product_id);
    const filteredProducts = sortedProductList.filter((product) => product.description.includes("Premium Plus"));
    const selectedProduct = filteredProducts.find(product => product.product_id === selectedProductId);
    const displayedProducts = isProductExpanded ? filteredProducts : filteredProducts.slice(0, 3);

    const lifetimeMonths = selectedProduct?.lifetime_of_product || 0;
    const currentDate = moment().isBefore("2024-11-11") ? moment("2024-11-11") : moment();
    const futureDate = currentDate.add(lifetimeMonths, 'months');

    const userToken = generateToken(aid, at);
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };
    const priceMap: { [key: string]: number } = { "(3 Months)": price3Months, "(6 Months)": price6Months };



    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (address.length > addressMaxLength) { toast('กรุณากรอก ที่อยู่ ไม่เกิน 200 ตัวอักษร'); return; }
        if (!selectedFile) { toast('กรุณาอัปโหลดภาพสลิปโอนเงิน'); return; };
        setSendSubmit(true);
        try {
            if (selectedFile) {
                const { upload_url, file_id } = await getSlackFileUploadUrl(selectedFile.name, selectedFile.size);
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('uploadUrl', upload_url);
                await uploadFileToServer(formData);
                await completeSlackUpload(file_id, accountName, true, selectedProduct.description_th, futureDate);
            }
            await createPrePromptPay();
            await updateAddress();
            await insertPendingSchoolRoom(aid);
            setShowMessenger(true);

        } catch (error) {
            console.error("Error during verification/sign-in:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        } finally {
            setSendSubmit(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const maxSizeBytes = 5 * 1024 * 1024; // 5MB

        if (!file.type.startsWith('image/')) {
            toast('กรุณาอัปโหลดภาพสลิปโอนเงิน');
        } else if (file.size > maxSizeBytes) {
            toast('ขนาดไฟล์ใหญ่เกิน 5MB กรุณาอัปโหลดรูปที่มีขนาดเล็กกว่านี้');
        } else {
            setSelectedFile(file);
            const fileNameDisplay = document.getElementById("payMobileFileName");
            if (fileNameDisplay) fileNameDisplay.textContent = file.name;
            return;
        }

        event.target.value = '';
        setSelectedFile(null);
    };

    const getSlackFileUploadUrl = async (filename: string, length: number) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/getUploadURLExternal`;
        const body = JSON.stringify({ filename, length });
        try {
            const response = await fetch(url, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const uploadFileToServer = async (formData: FormData) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/uploadExternal`;
        const headers: HeadersInit = { 'token': userToken };
        try {
            const response = await fetch(url, { method: 'POST', headers, body: formData });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const completeSlackUpload = async (file_id: string, name: string, isPremiumPlus: boolean, product: string, futureDate: moment.Moment) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/completeUploadExternal`;
        const expireTime = futureDate.format('DD/MM/YYYY');
        const body = JSON.stringify({ file_id, name, isPremiumPlus, product, expireTime });
        try {
            const response = await fetch(url, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const generateQrCode = async () => {
        const mobileNumber = '0205557003965';
        const payload = generatePayload(mobileNumber, { amount });
        const options: QRCodeToDataURLOptions = { color: { dark: '#003b6a', light: '#ffffff' } };
        try {
            const qrCodeDataURL = await qrcode.toDataURL(payload, options);
            setQrCodeDataURL(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    const updateAmount = () => {
        if (lifetimeMonths > 0) {
            const description = selectedProduct?.description || "";

            const matchedKey = Object.keys(priceMap).find(key => description.includes(key));
            const selectedPrice = matchedKey ? Number(priceMap[matchedKey]) : 0;
            const productPrice = selectedPrice + (Number(selectedProduct?.price) || 0);

            if (currentPremium?.expire_time) {
                const expireTime = moment(currentPremium.expire_time);

                if (expireTime.isBefore(futureDate)) {
                    const calculationDate = expireTime.isAfter(currentDate) ? currentDate : moment();
                    const daysBetweenNowAndExpire = Math.abs(expireTime.diff(calculationDate, 'days') + 1);
                    const deductionFromExpire = daysBetweenNowAndExpire * dailyRate;
                    let totalDeduction = deductionFromExpire;

                    if (futureDate.isAfter(cutoffDate)) {
                        const daysAfterCutoff = futureDate.diff(cutoffDate, 'days');
                        const additionalDeduction = daysAfterCutoff > 0 ? daysAfterCutoff * dailyRate : 0;
                        totalDeduction += additionalDeduction;
                    }

                    setAmount(productPrice - totalDeduction);
                } else {
                    if (futureDate.isAfter(cutoffDate)) {
                        const daysRemaining = calculateDaysUntilExpire(cutoffDate);
                        setAmount(daysRemaining * dailyRate);
                    } else {
                        setAmount(Number(selectedPrice));
                    }
                }
            } else {
                setAmount(Number(productPrice));
            }
        } else {
            setAmount(0);
        }
    };

    const checkUserSchoolStatus = async () => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/check-user?userId=${aid}`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(`${response.statusText}`);

            const result = await response.json();
            return result.exists;
        } catch (error) {
            console.error('Error fetching user school status:', error);
            return false;
        }
    };

    const calculateDaysUntilExpire = (expireTime: Moment) => {
        const diffDays = expireTime.diff(currentDate, 'days');
        return diffDays >= 0 ? diffDays + 1 : 0;
    };

    const checkAccessDataRedirect = async () => {
        const isUserInSchool = await checkUserSchoolStatus();
        if (!accessData.accessToken || isUserInSchool) history.push('/');
    };

    const checkCutoffDateRedirect = async () => {
        if (cutoffDate && currentDate.isSameOrAfter(cutoffDate)) history.push('/');
    };

    const changePlan = async (selectPlan: string) => {
        const product = filteredProducts.find((t) => t.product_id == selectPlan);
        dispatch(reloadTransaction(at, aid));
        setAmount(parseInt(product.price));
        setSelectedProductId(selectPlan);
    };

    const insertPendingSchoolRoom = async (userId: string) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/school-room/insert/pending`;
        const body = JSON.stringify({ userId });
        try {
            const response = await fetch(url, { method: 'POST', headers, body, });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error inserting pending school room:', error);
            throw error;
        }
    };

    const createPrePromptPay = async () => {

        if (currentPremium && currentPremium.expire_time && moment(currentPremium.expire_time).isSameOrAfter(futureDate)) {
            console.warn("Early return: Current premium expires after future date.");
            return;
        }

        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/payment/create/pre/promptpay/amount`;
        const body = JSON.stringify({ userId: aid, productId: selectedProductId, amount, isPremiumPlus: true });

        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
        } catch (error) {
            console.error("Error creating pre-prompt pay:", error);
            throw error;
        }
    };

    const fetchAdminData = async (
        endpoint: string, setter: (value: any) => void, defaultValue: any, processor: (value: any) => any = (value) => value
    ) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/payment/admin/${endpoint}`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(response.statusText);

            const result = await response.json();
            const value = result?.data?.[0]?.value ?? defaultValue;
            setter(processor(value));
        } catch (error) {
            console.error(`Error fetching ${endpoint}:`, error);
        }
    };

    const updateAddress = async () => {
        const fullAddress = `${address}, ${addressAppend}`;
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/update/address`;
        const body = JSON.stringify({ userId: aid, address: fullAddress });
        try {
            const response = await fetch(url, { method: 'PUT', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error updating address:', error);
        }
    };

    const handleSelectAddress = (address: string) => setAddressAppend(address);



    useEffect(() => {
        fetchAdminData('cutoff-date', setCutoffDate, currentDate, (value) => moment(value));
        fetchAdminData('plus-price-daily', setDailyRate, 0);
        fetchAdminData('plus-price3', setPrice3Months, 0);
        fetchAdminData('plus-price6', setPrice6Months, 0);

        dispatch(reloadTransaction(at, aid));
        const interval = setInterval(() => { dispatch(reloadTransaction(at, aid)) }, 6000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => { checkAccessDataRedirect() }, [accessData]);

    useEffect(() => { generateQrCode() }, [amount]);

    useEffect(() => { checkCutoffDateRedirect() }, [cutoffDate]);

    useEffect(() => {
        if (productList.length > 0 && filteredProducts && !selectedProductId) {
            setSelectedProductId(filteredProducts[0].product_id);
            setAmount(parseInt(filteredProducts[0].price));
        };
    }, [productList, selectedProductId]);

    useEffect(() => { if (selectedProductId) updateAmount() }, [cutoffDate, productList, selectedProductId]);



    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mobile-pay-bg-sign-up">
                <div className="row">
                    <div className="mobile-pay-step-2 col-12">
                        <div className="mobile-pay-premium-text-payment col-12">
                            <div>เลือกคอร์สเรียน</div>

                            <div className="mobile-pay-product-selection">
                                {displayedProducts.map((product) => {
                                    const basePrice = parseFloat(product.price);
                                    const additionalPrice = priceMap[`(${product.subscription_months} Months)`] || 0;
                                    const totalPrice = basePrice + Number(additionalPrice);
                                    const monthlyPrice = totalPrice / product.subscription_months;

                                    const lastProduct = filteredProducts[filteredProducts.length - 1];
                                    const lastBasePrice = parseFloat(lastProduct.price);
                                    const lastAdditionalPrice = priceMap[`(${lastProduct.subscription_months} Months)`] || 0;
                                    const lastTotalPrice = lastBasePrice + Number(lastAdditionalPrice);
                                    const lastMonthlyPrice = lastTotalPrice / lastProduct.subscription_months;

                                    const savingsPercentage = lastMonthlyPrice > 0
                                        ? Math.ceil((1 - (monthlyPrice / lastMonthlyPrice)) * 100)
                                        : 0;

                                    return (
                                        <div
                                            key={product.product_id}
                                            className={`mobile-pay-product-item ${selectedProductId === product.product_id ? 'selected' : ''}`}
                                            onClick={() => changePlan(product.product_id)}
                                        >
                                            <div className="mobile-pay-product-name">
                                                <label className="mobile-pay-product-radio">
                                                    <input
                                                        type="radio" name="product" value={product.product_id}
                                                        checked={selectedProductId === product.product_id} readOnly
                                                    />
                                                    {product.description_th}
                                                </label>
                                                {Number(savingsPercentage) > 0 && (
                                                    <div className="mobile-pay-savings-percentage">
                                                        ประหยัด {Number(savingsPercentage)}%
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mobile-pay-product-pricing">
                                                <div className="mobile-pay-monthly-price">
                                                    ฿ {Math.ceil(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })} ต่อเดือน
                                                </div>
                                                <div className="mobile-pay-total-price">
                                                    ทั้งหมด ฿ {totalPrice.toLocaleString()}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {filteredProducts.length > 3 && (
                                    <div onClick={() => setIsProductExpanded(!isProductExpanded)} className="mobile-pay-view-all-button">
                                        {isProductExpanded ? 'ปิด' : 'ดูทั้งหมด'}
                                    </div>
                                )}
                            </div>

                            <div className="mobile-pay-checkbox-list">
                                <ul>
                                    {[
                                        "ไลฟ์สอนสดฟรี",
                                        "ไลฟ์สอนสดพรีเมียม",
                                        "ชมคลิปบทเรียนสั้น",
                                        "ชมคลิปติวสอบเข้าโรงเรียนดัง",
                                        <><span>รายงานผลการเรียน</span><br /><span>พร้อมคำแนะนำ</span></>,
                                        "หนังสือประกอบการเรียน",
                                    ].map((label, index) => (
                                        <li key={index}>
                                            <input type="checkbox" id={`mobile-pay-checkbox-${index + 1}`} checked readOnly />
                                            <label htmlFor={`mobile-pay-checkbox-${index + 1}`}>{label}</label>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                        <div className="mobile-pay-price-display col-12">
                            ยอดที่ต้องชำระ – {amount.toLocaleString()} บาท
                        </div>

                        <div className="mobile-pay-qr col-12">
                            <img id="promptpay-logo" src={promptPay} alt="PromptPay Logo" />
                            {qrCodeDataURL && <img src={qrCodeDataURL} alt={`QR Code for ${amount} baht`} />}
                            <p>หมายเลขอ้างอิง: 0-2055-57003-96-5</p>
                        </div>

                        <div className="mobile-pay-address col-12 mt-4">
                            <input
                                type="text" id="addressAppend" value={addressAppend} readOnly required
                                onClick={() => setIsModalOpen(true)} className="form-control"
                                placeholder="เลือกจังหวัด อำเภอ ตำบล รหัสไปรษณีย์..."
                            />
                            <input
                                type="text" id="address" value={address} maxLength={addressMaxLength} required
                                onChange={(e) => setAddress(e.target.value)} className="form-control"
                                placeholder="กรอกที่อยู่ เลขที่บ้าน อาคาร ซอย..."
                            />
                            <AddressModal
                                isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSelect={handleSelectAddress}
                            />
                        </div>

                        <div className="mobile-pay-notes col-12">
                            <p>ชำระผ่าน Mobile Banking ได้โดย</p>
                            <ul className="mobile-pay-notes-list">
                                <li>Capture หน้าจอโทรศัพท์</li>
                                <li>นำภาพ QR ชำระผ่าน App ของธนาคาร</li>
                                <li>อัปโหลดสลิปหลักฐานการโอนเงินผ่านปุ่มด้านล่าง</li>
                                <li>ยอมรับเงื่อนไขและข้อตกลง แล้วกดสมัครใช้บริการ</li>
                                <li>แจ้งการชำระเงินกับแอดมินผ่านทาง Messenger</li>
                            </ul>
                        </div>

                        <div className="pay-mobile-image-upload col-12">
                            <input type="file" id="fileUpload" onChange={handleFileChange} />
                            <label htmlFor="fileUpload">
                                อัปโหลดสลิป
                                <img src={uploadIcon} alt="Upload" className="pay-mobile-upload-icon" />
                            </label>
                            <span id="payMobileFileName"></span>
                        </div>

                        <div className="mobile-pay-requirements col-12 mt-4 d-flex align-items-center">
                            <p>ข้าพเจ้ายอมรับ</p>
                            <p>
                                <label>
                                    <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข </a>
                                    และ
                                    <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy"> ข้อตกลงการใช้บริการ</a>
                                </label>
                                <span style={{ marginLeft: "10px" }}></span>
                                <input className="mobile-pay-accept mr-2" type="checkbox" id="accept-terms" required />
                            </p>
                        </div>
                    </div>

                    {!showMessenger && (
                        <div className="col-12 mobile-pay-register-button-container">
                            <button id="mobile-pay-register-button" className="m-auto" type="submit" disabled={sendSubmit}>
                                สมัครใช้บริการ
                                <img style={{ maxHeight: "8vh" }} src={loading} hidden={!sendSubmit} />
                            </button>
                        </div>
                    )}

                    {showMessenger && (
                        <div className="pay-mobile-messenger-container col-12">
                            <a
                                href={`https://line.me/R/ti/p/%40nockacademye456`}
                                className="pay-mobile-line-us-button col-12"
                                target="_blank"
                                onClick={() => history.push('/')}
                            >
                                <img src={lineIcon} alt="LINE Icon" className="msg-icon" />
                                แจ้งการชำระเงิน
                            </a>
                        </div>
                    )}

                </div>
            </div>

            <ToastContainer
                position="top-center" autoClose={3000} hideProgressBar newestOnTop
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
            />
        </form >
    );
};

export default PayMobilePremium;
